import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import PerimetresAPI from '../services/PerimetresAPI'
import {isObject} from '../Tools/TypeOf'
import {base64} from '../Tools/Tools'
import {
  builderAddMatchers,
  convertObjectKeysToBase64,
  REQUEST_STATUS_FULFILLED,
  REQUEST_STATUS_PENDING,
  REQUEST_STATUS_REJECTED,
} from '../Tools/Slice'
import Sperimetr from '../class/Sperimetr'
import SperimetrValidation from '../class/SperimetrValidation'

const initialState = {
  totalItems: 0,
  isLoading: false,
  isError: false,
  data: {},
  onPendingIsLoading: false,
  onPending: {},
  currentOnPending: {},
  onPendingTotalItems: 0,
  bulk: []
};

/**
 * Récupère les périmetre sur FLORAJET.sperimetr
 *
 * @type {AsyncThunk<{totalItems: *, data: *}, {readonly sortType?: *, readonly sortOrder?: *, readonly currentPage?: *}, AsyncThunkConfig>}
 */
export const fetchPerimetres = createAsyncThunk(
  'fetchPerimetres',
  async ({sortType, sortOrder, currentPage, codefl}) => {
    return PerimetresAPI.getPerimetres(sortType, sortOrder, currentPage, codefl)
  },
)

/**
 * Récupère les périmetre sur FLORAJET_PRO.sperimetr_validation
 *
 * @type {AsyncThunk<{totalItems: *, data: *}, {readonly sortType?: *, readonly sortOrder?: *, readonly currentPage?: *}, AsyncThunkConfig>}
 */
export const fetchOnPending = createAsyncThunk(
  'fetchOnPending',
  async ({sortType, sortOrder, currentPage, codefl}) => {
    return PerimetresAPI.getOnPending(sortType, sortOrder, currentPage, codefl)
  },
)

/**
 * Créer une demande de modification/ajout d'un périmetre.
 *
 * @type {AsyncThunk<Sperimetr, void, AsyncThunkConfig>}
 */
export const flushOnPendingPerimetres = createAsyncThunk(
  'flushOnPendingPerimetres',
  async (data) => {
    return PerimetresAPI.newPerimetresValidation(Object.values(data))
  },
)

/**
 * Met à jour un périmetre.
 * Met à jour la table FLORAJET.sperimetr (base master).
 * (modifications non soumises à validation des commerciaux).
 *
 * @type {AsyncThunk<{perimetreStateId: *, perimetre: Sperimetr}, {readonly perimetreStateId?: *, readonly perimetre?: *}, AsyncThunkConfig>}
 */
export const patchPerimetre = createAsyncThunk(
  'patchPerimetre',
  async ({perimetre, perimetreStateId}) => {
    return {
      perimetreStateId,
      perimetre: new Sperimetr(await PerimetresAPI.patchPerimetre(perimetre)),
    }
  },
)

/**
 * Demande de suppression d'un perimetre.
 *
 * @type {AsyncThunk<SperimetrValidation, void, AsyncThunkConfig>}
 */
export const deletePerimetre = createAsyncThunk(
  'deletePerimetre',
  async (perimetre) => {
    return new SperimetrValidation(
      await PerimetresAPI.deletePerimetre(perimetre),
    )
  },
)

export function builderAddOnPendingMatchers(builder, asyncThunks) {
  for (const asyncThunkProp in asyncThunks) {
    if (Object.prototype.hasOwnProperty.call(asyncThunks, asyncThunkProp)) {
      builder.addMatcher(asyncThunks[asyncThunkProp], (state, {type}) => {
        const requestStatusRegex = new RegExp(
          `/(${REQUEST_STATUS_PENDING}|${REQUEST_STATUS_REJECTED}|${REQUEST_STATUS_FULFILLED})`,
        )

        if (requestStatusRegex.test(type)) {
          const [, requestStatus] = type.match(requestStatusRegex)

          state.requestStatus = requestStatus
          state.onPendingIsLoading = requestStatus === REQUEST_STATUS_PENDING
        }
      })
    }
  }
}

export const perimetreSlice = createSlice({
  name: 'perimetre',
  initialState,
  reducers: {
    updatePerimetre: (state, {payload: {perimetre, id, prop, value}}) => {
      if (isObject(perimetre)) {
        state.data[base64(perimetre.idsperimetr)][prop] = value
      } else if (id) {
        state.data[base64(Number(id))][prop] = value
      }
    },
    addOnPending: (state, {payload}) => {
      if (payload instanceof SperimetrValidation) {
        state.currentOnPending = {
          ...state.currentOnPending,
          ...{[base64(payload.idsperimetr)]: payload},
        }
      }
    },
    removeOnPending: (state, {payload: perimetrValidation}) => {
      if (perimetrValidation instanceof SperimetrValidation) {
        delete state.currentOnPending[base64(perimetrValidation.idsperimetr)]
      }
    },
    resetPerimetreState: () => initialState,
    addPerimetreBulk: (state, {payload: idPerimetre}) => {
      state.bulk = [...state.bulk, ...[idPerimetre]]
    },
    removePerimetreBulk: (state, {payload: idPerimetre}) => {
      state.bulk = state.bulk.filter((id) => id !== idPerimetre)
    },
    resetPerimetreBulkState: (state) => {
      state.bulk = initialState.bulk
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPerimetres.fulfilled,
        (state, {payload: {data, totalItems}}) => {
          state.data = convertObjectKeysToBase64(
            data.map((data) => new Sperimetr(data)),
            'idsperimetr',
          )
          state.totalItems = totalItems
        },
      )
      .addCase(fetchOnPending.fulfilled, (state, {payload: {data}}) => {
        const existingIdscommunes = []
        const onPending = []

        data.forEach((data) => {
          if (!existingIdscommunes.includes(data.idscommunes)) {
            existingIdscommunes.push(data.idscommunes)
            onPending.push(new SperimetrValidation(data))
          }
        })

        state.onPending = convertObjectKeysToBase64(
          onPending,
          'idSperimetrValidation',
        )
        state.onPendingTotalItems = onPending.length
      })
      .addCase(
        patchPerimetre.fulfilled,
        (state, {payload: {perimetreStateId, perimetre}}) => {
          state.data[perimetreStateId] = perimetre
        },
      )
      .addCase(
        flushOnPendingPerimetres.fulfilled,
        (state, {payload: {data: sperimetrs}}) => {
          sperimetrs.forEach((sperimetr) => {
            state.onPending[base64(sperimetr.idsperimetr)] = sperimetr
          })

          state.currentOnPending = {}
        },
      )
      .addCase(deletePerimetre.fulfilled, (state, {payload: sperimetr}) => {
        sperimetr.deletion = true
        state.onPending[base64(sperimetr.idsperimetr)] = sperimetr
      })

    builderAddMatchers(builder, {
      fetchPerimetres,
      patchPerimetre,
    })

    builderAddOnPendingMatchers(builder, {
      flushOnPendingPerimetres,
      fetchOnPending,
      deletePerimetre,
    })
  },
})

export const patchState = (state) => (dispatch) => {
  for (const key in state.data) {
    if (
      Object.prototype.hasOwnProperty.call(state.data, key) &&
      state.data[key].updated
    ) {
      dispatch(
        patchPerimetre({perimetre: state.data[key], perimetreStateId: key}),
      )
    }
  }
}

export const {
  updatePerimetre,
  addOnPending,
  removeOnPending,
  resetPerimetreState,
  addPerimetreBulk,
  removePerimetreBulk,
  resetPerimetreBulkState
} = perimetreSlice.actions

export const selectPerimetre = (state) => state.perimetre

export default perimetreSlice.reducer
