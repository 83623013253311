import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import React, {useEffect, useMemo} from "react";
import Col from "react-bootstrap/Col";
import Box from "../../ui/box/Box";
import {AccessoryForm} from "./AccessoryForm";
import useFetch from "../../../hooks/useFetch";
import AccessoryAPI from "../../../services/AccessoryAPI";
import Checkbox from "../../ui/form/Checkbox";

export const AccessoriesForm = ({formik, colXs, colSm, order}) => {
    const [{data: accessories}] = useFetch(AccessoryAPI.getAccessories());
    const isMourningEvent = useMemo(() => formik.values.occasion === 5 || formik.values.occasion === '5', [formik.values.occasion]);

    function onCheckBubble(event) {
        formik.setFieldValue('bubble', event.target.checked)
    }

    useEffect(() => {
        if (formik.values.occasion === 5 || formik.values.occasion === '5') {
            formik.setFieldValue('accessories', []);
            formik.setFieldValue('bubble', false);
        }
    }, [formik.values.occasion]);

    // Sélection des bons accessoires dans le cas de la modification d'une commande en attente (senattente)
    useEffect(() => {
        if (order && order.accessories && order.accessories.length > 0) {
            const orderAccessoriesMap = formik.values.accessoriesMap;
            order.accessories.forEach((accessoryMap) => {
                const accessoriesMapKey = accessories.map(value => value.id).indexOf(accessoryMap.item_id); // Récupère l'index

                if (accessoriesMapKey >= 0) {
                    orderAccessoriesMap.set(accessoriesMapKey, accessoryMap);
                }
            })
        }
    }, [order, accessories]);

    useEffect(() => {
        if (isMourningEvent) {
            formik.setFieldValue('accessoriesMap', new Map());
        }
    }, [isMourningEvent]);

    return (
        <>
            <Box
                boxXs={colXs}
                boxSm={colSm}
                boxName={'accessories'}
                headerClassNames={'rounded-top'}
                header={true}
                headerContent={<h3 className="my-auto">Accessoires</h3>}
                headerPadding={'p-3'}
                boxContentPadding={'p-3'}
                content={
                    <>
                        <Row className={'mb-2'}>
                            <Col sm={12} md={5}>
                                <Checkbox
                                    id={'bubble'}
                                    name={'bubble'}
                                    type={'checkbox'}
                                    aria-label={"Avec bulle d'eau"}
                                    label={`Avec bulle d'eau ${isMourningEvent ? '(indisponible)' : ''}`}
                                    className={'mb-2'}
                                    checked={formik.values.bubble}
                                    onChange={(event) => onCheckBubble(event)}
                                    disabled={isMourningEvent}
                                />
                            </Col>
                            {formik.values.numberOfAccessories >= 3 && (
                                <Col sm={12} md={7} className={'d-flex justify-content-end'}>
                                    <strong className={'text-danger'}> Le nombre maximal d'accessoires par commande est de 3</strong>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {accessories.length > 0 && accessories.map((accessory, index) => (
                                <AccessoryForm key={index} index={index} accessory={accessory} formik={formik} />
                            ))}
                        </Row>
                    </>
                }
            />
        </>
    );
}

AccessoriesForm.propTypes = {
    formik: PropTypes.object.isRequired,
    colXs: PropTypes.number,
    colSm: PropTypes.number,
    order: PropTypes.object,
}