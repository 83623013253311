import PropTypes from 'prop-types'
import React from 'react'
import Form from 'react-bootstrap/Form'
import {InputGroup} from 'react-bootstrap'

const Field = ({
  as,
  asGroup,
  rows,
  name,
  label,
  labelRequired,
  classNames,
  value,
  defaultValue,
  fieldClassNames,
  onBlur,
  onChange,
  style,
  onFocus,
  pattern,
  step,
  placeholder = '',
  type = 'text',
  error = '',
  success = '',
  fieldRef,
  prepend,
  disabled = false,
  readOnly = false,
  min,
  max,
}) => (
  <Form.Group className={`${classNames} ${prepend && 'prepend'}`} as={asGroup}>
    {label && (
      <Form.Label htmlFor={name}>
        {label}
        {labelRequired && <span className="text-red"> *</span>}
      </Form.Label>
    )}
    <InputGroup>
      <Form.Control
        as={as}
        rows={rows}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        pattern={pattern}
        step={step}
        placeholder={placeholder || label}
        name={name}
        id={name}
        className={`${fieldClassNames} ${
          (error && 'is-invalid') || (success && 'is-valid')
        }`}
        style={style}
        ref={fieldRef}
        disabled={disabled}
        readOnly={readOnly}
        min={min}
        max={max}
      />
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </InputGroup>
  </Form.Group>
)

Field.propTypes = {
  as: PropTypes.string,
  asGroup: PropTypes.object,
  defaultValue: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  classNames: PropTypes.string,
  fieldClassNames: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  success: PropTypes.bool,
  step: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldRef: PropTypes.object,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Field
