import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import Table from 'react-bootstrap/Table'

import '../../../assets/scss/_tableCustom.scss'

import TableHeader from './TableHeader'
import {isFunction} from '../../../Tools/TypeOf'
import TableBody from './TableBody'
import {BOOTSTRAP_VARIANTS} from '../../../constants/Constants'
import AlertCustom from "../alert/AlertCustom";

const TableCustom = ({
  id,
  classNames,
  headerClassnames,
  isLoading,
  isError,
  headers,
  sorting,
  handleSortOrder,
  sortType,
  sortOrder,
  data,
  config,
  handleConfig,
  configLine,
  lineVariant,
  totals,
  full = true,
  rowDeletable,
  handleDeletableRow,
  responsive = true,
  deletableRowOnClick,
  bulkSelect,
  onSelect,
  isSelected
}) => {
  const [sortedConfig, setSortedConfig] = useState([])
  const isUnmounted = useRef(false)

  useEffect(() => {
    if (isFunction(handleConfig) && data.length > 0) {
      setSortedConfig(data.map((item) => handleConfig(item)))
    }
  }, [handleConfig, data])

  useEffect(() => {
    if (config) {
      let currentConfig = (config.length > 0 && config) || sortedConfig

      if (currentConfig && !isUnmounted.current && !full) {
        const newConf = {required: [], others: []}

        currentConfig.forEach((conf, i) => {
          conf.header = headers[i]
          if (
            Object.prototype.hasOwnProperty.call(conf, 'required') &&
            conf.required
          ) {
            newConf.required.push(conf)
          } else {
            newConf.others.push(conf)
          }
        })

        currentConfig = full
          ? newConf.required.concat(newConf.others)
          : newConf.required
      }

      setSortedConfig(currentConfig)
    }
  }, [headers, config, sortedConfig, rowDeletable])

  return (
      <>
        <Table
          id={id}
          hover
          responsive={responsive}
          className={`${(classNames && classNames) || ''}${
            rowDeletable && 'row-deletable'
          }`}>
          <TableHeader
            className={headerClassnames}
            headers={headers}
            sorting={sorting}
            handleSortOrder={handleSortOrder}
            sortType={sortType}
            sortOrder={sortOrder}
            config={sortedConfig}
            full={full}
            bulkSelect={bulkSelect}
          />

          {!isLoading && !isError && (
            <TableBody
              data={data}
              config={
              bulkSelect
                ? [...sortedConfig, ...[{type: 'bulk', func: onSelect, value: isSelected}]]
                : sortedConfig
            }
              configLine={configLine}
              lineVariant={lineVariant}
              totals={totals}
              full
              rowDeletable={rowDeletable}
              handleDeletableRow={handleDeletableRow}
              deletableRowOnClick={deletableRowOnClick}
            />
          )}
        </Table>
        {isError instanceof Object && (
            <AlertCustom
                heading={isError.heading}
                variant={isError.variant}
                bodyIcon={isError.icon}
                bodyContent={isError.content}
                bodyContentBold={isError.message}
                bodyContentItalic={isError.detail}
                transition={!!isError}
            />
        )}
    </>
  )
}

TableCustom.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  headerClassnames: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  config: PropTypes.array,
  handleConfig: PropTypes.func,
  lineVariant: PropTypes.oneOfType([
    PropTypes.oneOf(BOOTSTRAP_VARIANTS),
    PropTypes.func,
  ]),
  configLine: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.func,
  ]),
  handleSortOrder: PropTypes.func,
  headers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  sortType: PropTypes.string,
  sorting: PropTypes.array,
  totals: PropTypes.object,
  full: PropTypes.bool,
  rowDeletable: PropTypes.bool,
  handleDeletableRow: PropTypes.func,
  deletableRowOnClick: PropTypes.func,
  responsive: PropTypes.bool,
  bulkSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.func
}

export default TableCustom
