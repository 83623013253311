import {ACCESSORY_API} from "../constants/Constants";

const getAccessories = () => {
  return {
      name: 'accessoires',
      method: 'get',
      url: `${ACCESSORY_API}?order[type]=asc&visible=1`
  }
}

export default {
    getAccessories
}