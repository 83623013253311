export const ROOT_PATH = '/'
export const DASHBOARD_PATH = `${ROOT_PATH}tableau-de-bord`
export const DOCUMENTATION_PATH = `${ROOT_PATH}documentation`
export const PARTNERS_PATH = `${ROOT_PATH}partners`
export const MOVIES_PATH = `${ROOT_PATH}videos`
export const BUYINGS_PATH = `${ROOT_PATH}mes-achats`
export const CART_PATH = `${ROOT_PATH}panier`
export const CATALOG_PATH = `${ROOT_PATH}catalogue`
export const SHOP_PATH = `${ROOT_PATH}magasin`
export const VACATIONS_PATH = `${ROOT_PATH}conges`
export const PERIMETER_PATH = `${ROOT_PATH}perimetre`
export const COUNTERS_PATH = `${ROOT_PATH}compteurs`
export const LOG_PATH = `${ROOT_PATH}historique`
export const TRANSFERT_PATH = `${ROOT_PATH}virements`
export const CHECKS_PATH = `${ROOT_PATH}cheques-emis`
export const POINTINGS_PATH = `${ROOT_PATH}pointages`
export const CLIENTS_PATH = `${ROOT_PATH}clients`
export const SAV_PATH = `${ROOT_PATH}sav`
export const ORDER_ENTRY_PATH = `${ROOT_PATH}saisir-commande`
export const TRANSMISSIONS_PATH = `${ROOT_PATH}transmissions`
export const EXECUTIONS_PATH = `${ROOT_PATH}executions`
export const CUSTOMER_ADD_PATH = `${ROOT_PATH}customers/add`
export const TOP_10_PATH = `${ROOT_PATH}top-10`
export const USERS_PATH = `${ROOT_PATH}utilisateurs`
export const SHOPPERS_PATH = `${ROOT_PATH}livreurs`
export const LEVY_PATH = `${ROOT_PATH}prelevements`
