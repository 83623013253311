import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import {Link} from 'react-router-dom'
import moment from 'moment'
import React from 'react'
import {BrowserView, MobileView} from 'react-device-detect'
import Badge from 'react-bootstrap/Badge'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import AlertCustom from '../../ui/alert/AlertCustom'
import Box from '../../ui/box/Box'
import Field from '../../ui/form/Field'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import OrderCardGroup from '../../ui/card/OrderCardGroup'
import {dashboardManager} from '../../../hooks/DashboardManager'
import {TakePicture} from '../../ui/image/TakePicture'
import {useNotifications} from '../../../hooks/useNotifications'
import {SavModalBody} from '../sav/SavModalBody'
import {useShopperPrebook} from '../../../hooks/useShoppers'
import ShopperModals from '../../ui/modal/ShopperModals'
import {DashboardExecutions} from "./DashboardExecutions";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import {ExecutionsCards} from "../executions/ExecutionsCards";
import {DashboardSavs} from "./DashboardSavs";

const Dashboard = () => {
    useNotifications();
    const executionsInPendingCount = useStoreExecutions((state) => state.executionsInPendingCount);

    const {
        user,
        isLoadingExec,
        isErrorExec,
        isLoadingTrans,
        isErrorTrans,
        sortTypeTrans,
        sortOrderTrans,
        transmissions,
        isLoadingSav,
        isErrorSav,
        savs,
        modalPicture,
        picture,
        modalTakePicture,
        transition,
        modalState,
        isErrorState,
        order,
        sav,
        modalSav,
        isErrorSavComment,
        refus,
        modalRefus,
        setModalRefus,
        formikRefus,
        isLoadingRefus,
        modalOrder,
        setModalOrder,
        isErrorPdf,
        isLoadingPdf,
        modalDeleteOrder,
        isLoadingDelete,
        modalDeleteMessage,
        isErrorDelete,
        handleShowOrder,
        handleSortOrderTrans,
        handleDeleteOrder,
        handleSortOrderSav,
        handleShowSav,
        handleChangeRefus,
        setModalTakePicture,
        setModalPicture,
        setModalSav,
      } = dashboardManager()

    const actionTrans = [
        {
            name: 'Visualiser',
            nameParam: 'numcde',
            color: 'primary',
            icon: 'eye',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleShowOrder,
            funcParam: 'numcde',
            filter: 'actions',
            filterParam: 'visu',
        },
    ]

    const actionSAV = [
        {
            name: 'Visualiser',
            nameParam: 'reclaNumcde',
            color: 'primary',
            icon: 'eye',
            iconColor: 'white',
            classnames: 'm-1 padding-icon',
            func: handleShowOrder,
            funcParam: 'reclaNumcde',
        },
        {
            name: 'Afficher les détails du SAV',
            color: 'primary',
            icon: 'file-contract',
            iconColor: 'white',
            iconClassNames: 'margin-icon',
            classnames: 'm-1',
            func: handleShowSav,
        },
    ]

    return (
        <>
            <BrowserView renderWithFragment={true}>
                <Title title={'Mon florajet - Tableau de bord'}/>
                <DashboardExecutions/>

                <Box
                    boxName="transmissions dashboard-transmissions scroll"
                    boxClassNames="flex-column mb-4"
                    header={true}
                    headerClassNames="rounded-top"
                    headerTextColor="white"
                    headerContent={
                        <>
                            <Row>
                                <Col className={'my-auto'}>
                                    <h3 className="mr-3 my-auto">Mes dernières transmissions</h3>
                                </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    {user.constaints && user.constaints.transmitter && (
                                        <Link
                                            to="/saisir-commande"
                                            role="button"
                                            className="btn d-flex align-items-center btn-sm ff-bold mr-2">
                                            Saisir une commande
                                        </Link>
                                    )}

                                    <Link
                                        to="/transmissions"
                                        role="button"
                                        className="btn d-flex align-items-center btn-sm ff-bold">
                                        Voir tout
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    }
                    boxContentClassNames="rounded-bottom"
                    content={
                        <>
                            <TableCustom
                                isLoading={isLoadingTrans}
                                isError={isErrorTrans}
                                classNames="mb-0"
                                headers={[
                                    'État',
                                    'Date et moment de livraison',
                                    'Destinataire',
                                    'Code postal ville',
                                    'Montant',
                                    'Numéro exécutant',
                                    'Actions',
                                ]}
                                sorting={[
                                    'datliv',
                                    'nomdes',
                                    'localite',
                                    'montantcdee',
                                    'codex',
                                ]}
                                handleSortOrder={handleSortOrderTrans}
                                sortType={sortTypeTrans}
                                sortOrder={sortOrderTrans}
                                data={transmissions}
                                config={[
                                    {
                                        type: 'picto',
                                        key: 'etatextranet',
                                    },
                                    {
                                        type: 'multilines',
                                        key: [
                                            {type: 'date', key: 'datliv'},
                                            {type: 'moment', key: 'momentjour'},
                                        ],
                                    },
                                    {type: 'text', key: 'nomdes'},
                                    {
                                        type: 'multilines',
                                        key: [
                                            {type: 'text', key: 'codpostal'},
                                            {type: 'text', key: 'localite'},
                                        ],
                                    },
                                    {type: 'currency', key: 'amountvat'},
                                    {
                                        type: 'multilines',
                                        key: [
                                            {type: 'text', key: 'codex'},
                                            {type: 'phone', key: 'telseek', array: 'extra'},
                                        ],
                                    },
                                    {
                                        type: 'buttons',
                                        key: actionTrans,
                                    },
                                ]}
                            />

                            {isLoadingTrans && <Loader/>}
                        </>
                    }
                />

                <DashboardSavs />
      </BrowserView>

      <MobileView renderWithFragment={true}>
        <Col sm={12}>
          <Tabs
            defaultActiveKey="exec"
            id="dashboard"
            className={'tabs-underline col nav-pills nav-fill pr-0'}>
            <Tab
              eventKey="exec"
              tabClassName={'fs-16'}
              title={
                <React.Fragment>
                  Exécutions{' '}
                  {!isLoadingExec &&
                    !isErrorExec &&
                      executionsInPendingCount > 0 && (
                      <Badge pill className={'badge-danger'}>
                        {executionsInPendingCount}
                      </Badge>
                    )}
                </React.Fragment>
              }>
              {/* <OrderCardGroup */}
              {/*  isLoading={isLoadingExec} */}
              {/*  isError={isErrorExec} */}
              {/*  data={executions} */}
              {/*  actionUrl={`/executions`} */}
              {/*  actionButtons={actionExec} */}
              {/*  user={user} */}
              {/* /> */}
              <ExecutionsCards />
            </Tab>
            <Tab eventKey="trans" title="Transmissions" tabClassName={'fs-16'}>
              <OrderCardGroup
                isLoading={isLoadingTrans}
                isError={isErrorTrans}
                data={transmissions}
                actionUrl={`/transmissions`}
                actionButtons={actionTrans}
                user={user}
              />
            </Tab>
            <Tab eventKey="sav" title="S.A.V" tabClassName={'fs-16'}>
              <OrderCardGroup
                isLoading={isLoadingSav}
                isError={isErrorSav}
                data={savs}
                actionUrl={`/sav`}
                actionButtons={actionSAV}
                user={user}
              />
            </Tab>
          </Tabs>
        </Col>
      </MobileView>

      <ModalCustom
        show={modalPicture}
        onHide={() => setModalPicture(false)}
        id="modal-picture"
        size="md"
        bodyClassname="p-3"
        content={
          <>
            <div className="mb-3">
              <Image
                onError={(e) => {
                  e.target.src =
                    'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                }}
                src={picture}
                className="rounded-xl img-fluid"
              />
            </div>
          </>
        }
        buttons={[
          {
            text: 'Fermer',
          },
        ]}
      />
      <TakePicture
        onHide={setModalTakePicture}
        showModalTakePicture={modalTakePicture}
        order={order}
      />

      <ModalCustom
        show={modalState}
        onHide={() => setModalState(false)}
        id="modal-state"
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant={isErrorState.variant}
              heading={isErrorState.heading}
              headingTitle={isErrorState.headingTitle}
              headingIconName={isErrorState.headingIconName}
              bodyContentHtml={isErrorState.content}
              transition={transition}
            />
          </>
        }
        buttons={[
          {
            text: 'Fermer',
          },
        ]}
      />

      {refus && order && (
        <ModalCustom
          show={modalRefus}
          onHide={() => setModalRefus(false)}
          id="modal-refus"
          title="Motif de refus de la commande"
          titleClassname="text-center mb-3"
          bodyClassname="text-left"
          content={
            <>
              <Col md={{span: 10, offset: 1}} className="text-left">
                <div className="fs-20 text-center my-3">
                  Merci de nous indiquer le motif de refus de la commande
                  <span className="ff-bold">
                    {' '}
                    {(order.item && order.item.numcde) || order.id}
                  </span>
                </div>

                <Form
                  onSubmit={(event) => {
                    event.preventDefault()
                    formikRefus.handleSubmit()
                  }}
                  className="mb-3">
                  {refus &&
                    refus.map((item, index) => (
                      <Form.Check
                        key={index}
                        custom
                        type="radio"
                        className="mb-3"
                        label={item.refusDesc}
                        name="refus"
                        value={item.refusIdx}
                        id={`refus-${item.refusIdx}`}
                        defaultChecked={
                          formikRefus.values.refus === item.refusIdx
                        }
                        onChange={(event) => handleChangeRefus(event)}
                      />
                    ))}
                  {formikRefus.values.refus === '1' && (
                    <Field
                      as="textarea"
                      rows={8}
                      name="refusmessage"
                      value={formikRefus.values.refusmessage}
                      onChange={formikRefus.handleChange}
                      onBlur={formikRefus.handleBlur}
                      placeholder="Que proposez-vous en remplacement ? *"
                      fieldClassNames="bg-white"
                      classNames="my-3"
                      error={
                        formikRefus.touched.refusmessage &&
                        formikRefus.errors.refusmessage
                      }
                      success={
                        formikRefus.touched.refusmessage &&
                        !formikRefus.errors.refusmessage
                      }
                    />
                  )}
                  <Button
                    type="submit"
                    disabled={formikRefus.isSubmitting}
                    className="">
                    {isLoadingRefus ? (
                      <Loader
                        variant="white"
                        size="sm"
                        classname="my-1"
                        style={{
                          marginLeft: '21px',
                          marginRight: '20px',
                        }}
                      />
                    ) : (
                      'Envoyer'
                    )}
                  </Button>
                </Form>
              </Col>
            </>
          }
          footer={true}
          buttonsFooter={[
            {
              text: 'Fermer',
            },
          ]}
        />
      )}

      {order && (
        <>
          <ModalCustom
            show={modalOrder}
            onHide={() => setModalOrder(false)}
            size={'sm'}
            id="modal-order"
            header={false}
            bodyClassname="text-left"
            content={
              <>
                <AlertCustom
                  variant={isErrorPdf.variant}
                  heading={isErrorPdf.heading}
                  bodyContent={isErrorPdf.content}
                  transition={transition}
                />

                {isLoadingPdf && <Loader />}
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: 'Fermer',
              },
            ]}
          />

          <ModalCustom
            show={modalDeleteOrder}
            onHide={() => setModalDeleteOrder(false)}
            id="modal-order-delete"
            bodyClassname="p-5"
            content={
              <>
                <h3>Etes-vous sûr de vouloir supprimer cette commande ?</h3>
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: isLoadingDelete ? (
                  <Loader variant="white" size="sm" classname="my-1 mx-2" />
                ) : (
                  'Confirmer'
                ),
                func: () => handleDeleteOrder(order),
                classNames: 'mx-2',
              },
              {
                text: 'Annuler',
                classNames: 'btn-grey-darker mx-2',
              },
            ]}
          />

          <ModalCustom
            show={modalDeleteMessage}
            onHide={() => setModalDeleteMessage(false)}
            id="modal-delete-message"
            content={
              <>
                <AlertCustom
                  classname="text-left"
                  variant={isErrorDelete.variant}
                  heading={isErrorDelete.heading}
                  headingTitle={isErrorDelete.headingTitle}
                  headingIconName={isErrorDelete.headingIconName}
                  bodyContent={isErrorDelete.content}
                  bodyContentItalic={isErrorDelete.detail}
                  transition={transition}
                />
              </>
            }
            buttons={[
              {
                text: 'Fermer',
                classNames: 'mx-2',
              },
            ]}
          />
        </>
      )}

      {sav && (
        <ModalCustom
          show={modalSav}
          onHide={() => setModalSav(false)}
          id="modal-sav"
          title={`Détails du SAV de la commande ${sav.recla_numcde}`}
          titleClassname="text-center mb-3"
          bodyClassname="text-left"
          content={
            <>
              <Col className="text-left">
                <AlertCustom
                  variant={isErrorSavComment.variant}
                  heading={isErrorSavComment.heading}
                  headingTitle={isErrorSavComment.headingTitle}
                  headingIconName={isErrorSavComment.headingIconName}
                  bodyContent={isErrorSavComment.content}
                  transition={transition}
                  dismissible={true}
                  onClose={() => setTransition(false)}
                  classname="m-0"
                />

                <SavModalBody sav={sav} />
              </Col>
            </>
          }
          footer={true}
          buttonsFooter={[
            {
              text: 'Fermer',
            },
          ]}
        />
      )}

      {/* <ShopperModals */}
      {/*  handleCancellationModal={handleCancellationModal} */}
      {/*  executions={executions} */}
      {/* /> */}
    </>
  )
}

export default Dashboard
