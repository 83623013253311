import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {builderAddMatchers} from '../Tools/Slice'
import CommuneAPI from '../services/CommuneAPI'

/**
 * Récupère toutes les vkilles de la table Florajet.Scommunes
 *
 * @type {AsyncThunk<{totalItems: *, data: *}, void, AsyncThunkConfig>}
 */
export const initCommuneSearchData = createAsyncThunk(
  'initCommuneSearchData',
  async () => {
    return CommuneAPI.getAllMinial()
  },
)

export const communeSearchSlice = createSlice({
  name: 'communeSearch',
  initialState: {
    totalItems: 0,
    isLoading: false,
    isError: false,
    data: [],
    result: [{codpostal: '', localite: '', idscommunes: ''}],
  },
  reducers: {
    /**
     * Recherche dans l'état, la commune recherchée
     *
     * @param state
     * @param payload
     */
    searchByPostalCode: (state, {payload}) => {
      const regexp = new RegExp(`^${payload}`)
      const result = []
      console.log(state.data && state.data.length > 0)
      if (state.data && state.data.length > 0) {
        state.data.forEach((commune) => {
          if (regexp.test(commune.codpostal)) {
            result.push(commune)
          }
        })
      }

      state.result = result
    },
    /**
     * Recherche dans l'état, le code postal recherché
     *
     * @param state
     * @param payload
     */
    searchByLocalite: (state, {payload}) => {
      const regexp = new RegExp(payload, 'i')
      const result = []

      state.data.forEach((commune) => {
        if (regexp.test(commune.localite)) {
          result.push(commune)
        }
      })

      result.sort((comm1, comm2) => {
        return (
          comm1.localite.toUpperCase().indexOf(payload.toUpperCase()) -
          comm2.localite.toUpperCase().indexOf(payload.toUpperCase())
        )
      })

      state.result = result
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      initCommuneSearchData.fulfilled,
      (state, {payload: {data, totalItems}}) => {
        state.data = data
        state.totalItems = totalItems
      },
    )
    builderAddMatchers(builder, {initCommuneSearchData})
  },
})

/**
 * Initialise l'état connenant toutes les communes si l'état est vide.
 *
 * Conseil: Initialiser avec initCommuneSearchData()
 * pour aller chercher les communes qu'une seul fois.
 *
 * @returns {(function(*, *): void)|*}
 */
export const initCommuneSearch = () => (dispatch, getState) => {
  const {communeSearch: communeSearchState} = getState()

  if (communeSearchState.data.length <= 0) {
    dispatch(initCommuneSearchData())
  }
}

/**
 * Recheche une commune ou un code postal.
 *
 * S'il n'y a que des chiffres dans search,
 * la recherche sera par code postals.
 *
 * @param search
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const searchCity = (search = null) => async (dispatch, getState) => {
  const {communeSearch: communeSearchState} = getState()
  const zipcodePattern = new RegExp(/\d{1,5}/)

  if (communeSearchState.data && communeSearchState.data.length <= 0 || !search) {
    communeSearchState.isLoading = true
    await CommuneAPI.getAllMinial().then((response) => {
      communeSearchState.data = response.data
      communeSearchState.isLoading = false
    })
  }

  if (zipcodePattern.test(search)) {
    dispatch(searchByPostalCode(search))
  } else {
    dispatch(searchByLocalite(search))
  }
}

export const {searchByPostalCode, searchByLocalite} = communeSearchSlice.actions

export const selectCommuneSearch = (state) => state.communeSearch

export default communeSearchSlice.reducer
